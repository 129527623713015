import React, { useEffect, useRef, useState } from "react";
import {
  wrapper,
  title,
  subtitle,
  books,
  oval,
  itemWrapper,
  itemContainer,
  itemImg,
  itemTitle,
  itemDescription,
  sliderWrapper,
  dotImg11,
  dotImg12,
  dotImg13,
  dotImg14,
  dotImg21,
  dotImg22,
  dotImg23,
  dotImg31,
  dotImg32,
  dotImg33,
  dotImg34,
  dotImg35,
  dotWrapper,
  sliderClass,
} from "./Features.module.scss";
//import BG from '../../assets/images/pixie/pixie-shadow.svg'
import Slider from "react-slick";
import featureImg1 from "../../assets/images/discover/disc1@2x.png";
import featureImg2 from "../../assets/images/discover/disc2@2x.png";
import featureImg3 from "../../assets/images/discover/disc3@2x.png";
import featureImg4 from "../../assets/images/discover/disc4@2x.png";
import featureImg5 from "../../assets/images/discover/disc5@2x.png";
import featureImg6 from "../../assets/images/discover/disc6@2x.png";
import featureImg7 from "../../assets/images/discover/disc7@2x.png";
import featureImg8 from "../../assets/images/discover/disc8@2x.png";
import dot1 from "../../assets/images/pixel-dots/dot1.svg";
import dot2 from "../../assets/images/pixel-dots/dot2.svg";
import dot3 from "../../assets/images/pixel-dots/dot3.svg";
import { StaticImage } from "gatsby-plugin-image";
const Features = () => {
  const sliderRef = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);
  const settings = {
    variableWidth: true,
    speed: 500,
    pauseOnHover: false,
    swipeToSlide: false,
    focusOnSelect: false,
    infinite: false,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (_current, next) => setActiveSlide(next),
  };

  useEffect(() => {
    if (window.innerWidth > 1100 && activeSlide > 5) {
      sliderRef.current.slickGoTo(5);
    }
  }, [activeSlide]);

  const featuresData = [
    {
      icon: featureImg1,
      title: "What skills do you need to land a frontend job",
      description:
        "Knowing how to code is a must have before you apply. Learn what skills are a minimum to land a job.",
      alt: "unicorn emoji",
    },
    {
      icon: featureImg2,
      title: "How to create portfolio that stands out",
      description:
        "I get over 100 applications for every job posting. Discover how to stand out amongst the crowd.",
      alt: "graduation cap emoji",
    },
    {
      icon: featureImg3,
      title: "How to use your social media presence",
      description:
        "Using Twitter or LinkedIn you can find a job without even looking. Learn this skill!",
      alt: "crystal ball emoji",
    },
    {
      icon: featureImg4,
      title: "The secret of success in online and phone interviews",
      description:
        "Online interviews are becoming a standard. Find the tricks to make them easier.",
      alt: "sunglasses emoji",
    },
    {
      icon: featureImg5,
      title: "How to nail the on-site interview",
      description:
        "On-site interview is the most challenging part of the recruitment process. Learn how to excel at it.",
      alt: "monocle emoji",
    },
    {
      icon: featureImg6,
      title:
        "Strategies to increase your chances to land a job after the interview",
      description:
        "Recruitment doesn't end with the interview. Discover how to improve your chances after the interview.",
      alt: "brain emoji",
    },
    {
      icon: featureImg7,
      title: "The secret trick to getting you ahead of all of your competition",
      description:
        "Discover a trick that helped me and many others to beat competition even before the recruitment even begun.",
      alt: "beer emoji",
    },
    {
      icon: featureImg8,
      title:
        "Resources to help you get prepared for the recruitment process even better",
      description:
        "This book is one of the most comprehensive resources you can find. But there are other great places to learn. We'll help you find them.",
      alt: "tools emoji",
    },
  ];

  return (
    <section className={wrapper}>
      <div className={oval} />
      <div className={dotWrapper}>
        <img src={dot1} className={dotImg11} alt={""} role={"presentation"} />
        <img src={dot1} className={dotImg12} alt={""} role={"presentation"} />
        <img src={dot1} className={dotImg13} alt={""} role={"presentation"} />
        <img src={dot1} className={dotImg14} alt={""} role={"presentation"} />
        <img src={dot2} className={dotImg21} alt={""} role={"presentation"} />
        <img src={dot2} className={dotImg22} alt={""} role={"presentation"} />
        <img src={dot2} className={dotImg23} alt={""} role={"presentation"} />
        <img src={dot3} className={dotImg31} alt={""} role={"presentation"} />
        <img src={dot3} className={dotImg32} alt={""} role={"presentation"} />
        <img src={dot3} className={dotImg33} alt={""} role={"presentation"} />
        <img src={dot3} className={dotImg34} alt={""} role={"presentation"} />
        <img src={dot3} className={dotImg35} alt={""} role={"presentation"} />
      </div>
      <StaticImage
        src={"../../assets/images/pages-Unicorn@2x.png"}
        formats={["webp", "avif"]}
        loading={"lazy"}
        placeholder={"blurred"}
        className={books}
        alt={""}
        role={"presentation"}
        width={631}
        height={206}
      />
      <h2 className={title}>
        Find Your First Frontend Job is
        <br />
        <span>+100 pages</span> of professional knowledge,
        <br />
        tips, tricks and industry know-how
      </h2>
      <h3 className={subtitle}>What will you discover?</h3>
      <div>
        <div className={sliderWrapper}>
          <Slider {...settings} className={sliderClass} ref={sliderRef}>
            {featuresData.map((feature, index) => (
              <div className={itemWrapper} key={index}>
                <div className={itemContainer}>
                  <img
                    src={feature.icon}
                    className={itemImg}
                    alt={feature.alt}
                  />
                  <h3 className={itemTitle}>{feature.title}</h3>
                  <p className={itemDescription}>{feature.description}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Features;
