// extracted by mini-css-extract-plugin
export const wrapper = "MadeBy-module--wrapper--djgis";
export const oval1 = "MadeBy-module--oval1--36r1Q";
export const oval2 = "MadeBy-module--oval2--HgIZ7";
export const title = "MadeBy-module--title--13HoS";
export const heartIcon = "MadeBy-module--heartIcon--bqPHh";
export const peopleContainer = "MadeBy-module--peopleContainer--30M4U";
export const container = "MadeBy-module--container--1VKl0";
export const person = "MadeBy-module--person--2_AsW";
export const personLink = "MadeBy-module--personLink--3sd9r";
export const personImage = "MadeBy-module--personImage--ypVRu";
export const personName = "MadeBy-module--personName--EW8Pf";
export const personMedium = "MadeBy-module--personMedium--n3oLX";
export const personTwitter = "MadeBy-module--personTwitter--2tt_C";
export const personLinkedIn = "MadeBy-module--personLinkedIn--2RE1a";
export const feature = "MadeBy-module--feature--27W9x";
export const featureContainer = "MadeBy-module--featureContainer--2iy9B";
export const featureIcon = "MadeBy-module--featureIcon--2FNGd";
export const featureText = "MadeBy-module--featureText--6dj9-";