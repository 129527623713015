// extracted by mini-css-extract-plugin
export const wrapper = "Header-module--wrapper--1c43D";
export const bookCover = "Header-module--bookCover--1S7zM";
export const rainbowMobile = "Header-module--rainbowMobile--b7osw";
export const coverFeature = "Header-module--coverFeature--2MSYk";
export const rating = "Header-module--rating--K6YYF";
export const textContainer = "Header-module--textContainer--2Qxt9";
export const imageContainer = "Header-module--imageContainer--33k5t";
export const rainbow = "Header-module--rainbow--6qhY6";
export const shape = "Header-module--shape--1Xuzc";
export const bg = "Header-module--bg--1_O19";
export const rectangle = "Header-module--rectangle--Xp56w";
export const title = "Header-module--title--2NCtK";
export const description = "Header-module--description--1i4lX";
export const buttonContainer = "Header-module--buttonContainer--3oin1";
export const discount = "Header-module--discount--3aRHC";
export const mobileButtonContainer = "Header-module--mobileButtonContainer--1Ot--";