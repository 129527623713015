import React from "react";
import {
  wrapper,
  container,
  bookOne,
  bookTwo,
  bookImage,
  bookSubtitle,
  bookTitle,
  bookWrapper,
  shape,
} from "./Books.module.scss";
import Link from "../Link/Link";
import imgShape from "../../assets/images/cloud2.svg";
import { StaticImage } from "gatsby-plugin-image";
const Books = () => {
  return (
    <footer className={wrapper}>
      <div className={container}>
        <div className={bookOne}>
          <div className={bookWrapper}>
            <img
              src={imgShape}
              className={shape}
              role={"presentation"}
              alt={""}
            />
            <img
              src={imgShape}
              className={shape}
              role={"presentation"}
              alt={""}
            />
            <img
              src={imgShape}
              className={shape}
              role={"presentation"}
              alt={""}
            />
            <StaticImage
              formats={["webp", "avif"]}
              src={"../../assets/images/coverUni.png"}
              width={220}
              height={353}
              className={bookImage}
              alt={"Book cover"}
            />
            <div className={bookSubtitle}></div>
            <div className={bookTitle}>
              Learn how to become
              <br />a successful developer
              <br />
              and earn more money!
            </div>
            <div></div>
            <Link
              arrow={true}
              href={"https://gumroad.com/l/aqWog"}
              target="_blank"
              rel="noreferrer"
            >
              I’M BUYING THE BOOK
            </Link>
          </div>
        </div>
        <div className={bookTwo}>
          <div className={bookWrapper}>
            <StaticImage
              formats={["webp", "avif", "png"]}
              src={"../../assets/images/book2.png"}
              width={220}
              height={353}
              className={bookImage}
              alt={'"Designing User Interfaces" book cover'}
            />
            <div className={bookSubtitle}>
              100% positive reviews:{" "}
              <span role={"img"} aria-label={"stars"}>
                ⭐️⭐️⭐️⭐️⭐️
              </span>{" "}
              (4.98)
            </div>
            <div className={bookTitle}>
              “This book is the best.
              <span role={"img"} aria-label={"clapping-hands heart"}>
                👏❤️
              </span>
              <br />
              Still reading and I love it.”
            </div>
            <div>@uiraygan on Instagram</div>
            <Link
              arrow={true}
              modifier={"green"}
              href={"https://designingui.com/"}
              target="_blank"
              rel="noreferrer"
            >
              CHECK IT OUT
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Books;
