import * as React from "react";
import Header from "../components/Header/Header";
import TweetCarousel from "../components/TweetCarousel/TweetCarousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Features from "../components/Features/Features";
import BuyBook from "../components/BuyBook/BuyBook";
import BookPreview from "../components/BookPreview/BookPreview";
import MadeBy from "../components/MadeBy/MadeBy";
import JoinInsta from "../components/JoinInsta/JoinInsta";
// import Newsletter from "../components/Newsletter/Newsletter";
import Books from "../components/Books/Books";
import Helmet from "react-helmet";
const IndexPage = () => {
  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <link
          rel="preload"
          as="font"
          href={"./fonts/EuclidCircularB-Light-WebS.woff2"}
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          as="font"
          href={"./fonts/EuclidCircularB-Regular-WebS.woff2"}
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          as="font"
          href={"./fonts/EuclidCircularB-Semibold-WebS.woff2"}
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <title>Land your first frontend job | Frontend Unicorn Series</title>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta
          name="msapplication-TileImage"
          content="ms-icon-144x144.d30c5d53.png"
        />
        <meta name="theme-color" content="#ffffff" />
        <meta
          name="description"
          content="Do you want to land frontend job but don't know how to nail the recruitment process? Lean how to stand out."
        />
        <meta
          name="keywords"
          content="Frontend, Programming, Frontend Developer, UX, UI, Interface, user experience, book, ebook, publication, workshop"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@hype4design" />
        <meta name="twitter:creator" content="Szymon Adamiak" />
        <meta name="twitter:title" content="Find Your First Frontend Job" />
        <meta
          name="twitter:description"
          content="Do you want to land frontend job but don't know how to nail the recruitment process? Lean how to stand out.."
        />
        <meta
          name="twitter:image"
          content="https://findfrontendjob.com/hero.jpg"
        />
        <meta
          property="og:url"
          content="https://findfrontendjob.com/index.html"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Frontend Unicorn" />
        <meta
          property="og:image"
          content="https://findfrontendjob.com/hero.jpg"
        />
        <meta
          property="og:description"
          content="Do you want to land frontend job but don't know how to nail the recruitment process? Lean how to stand out."
        />
        <meta property="og:site_name" content="Frontend Unicorn" />
        <meta property="article:author" content="https://findfrontendjob.com" />
        <meta
          property="article:publisher"
          content="https://findfrontendjob.com"
        />
        <script
          defer
          data-domain="findfrontendjob.com"
          src="https://plausible.io/js/plausible.js"
        ></script>
      </Helmet>
      <div className="allWrapper">
        <Header />
        <TweetCarousel />
        <main className={"mainClass"}>
          <Features />
          <BuyBook />
          <BookPreview />
          <MadeBy />
          <JoinInsta />
          {/* <Newsletter /> */}
          <Books />
        </main>
      </div>
    </>
  );
};

export default IndexPage;
