import React from "react";
import {
  wrapper,
  title,
  description,
  flexContainer,
  bookImage,
  summaryWrapper,
  upper,
  textWrapper,
  textContainer,
  upperIcon,
  bonus,
  bottom,
  regPriceWrapper,
  regPriceText,
  regPriceNumber,
  specPriceWrapper,
  specPriceEmoji,
  specPriceText,
  specPriceNumber,
  separator,
  saveWrapper,
  imageWrapper,
  coverFeature,
} from "./BuyBook.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import Link from "../Link/Link";
const BuyBook = () => {
  return (
    <section className={wrapper}>
      <h2 className={title}>
        Buy the eBook
        <br />
        “Find Your First Frontend Job”
      </h2>
      <div className={description}>
        <span role={"img"} aria-label={"stars"}>
          ⭐️⭐️⭐️⭐️⭐️
        </span>
        (5.00)
      </div>
      <h3 className={description}>What exactly will you get?</h3>
      <div className={flexContainer}>
        <div className={imageWrapper}>
          <StaticImage
            formats={["webp", "avif"]}
            width={336}
            height={540}
            src={"../../assets/images/cover.png"}
            className={bookImage}
            alt={"book cover"}
          />
          <div className={coverFeature}>
            <span>100%</span>positive
            <br />
            reviews
          </div>
          <div className={coverFeature}>
            <span>+100</span>pages
          </div>
        </div>
        <div className={summaryWrapper}>
          <div className={upper}>
            <div className={textWrapper}>
              <span className={upperIcon} role={"img"} aria-label={"book"}>
                📒
              </span>
              <p className={textContainer}>
                <strong>A colorful 100+ pages PDF eBook</strong> with lots of
                knowledge, industry know-how, tips, tricks and experiences we
                while recruiting hundreds of candidates.
              </p>
            </div>
            <span className={bonus}>Bonus materials:</span>
            <div className={textWrapper}>
              <span className={upperIcon} role={"img"} aria-label={"gift"}>
                🎁
              </span>
              <p className={textContainer}>
                25% discount code for our second book -{" "}
                <a href="https://h4academy.gumroad.com/l/frontendunicorn">
                  Frontend Unicorn
                </a>
              </p>
            </div>
          </div>
          <div className={bottom}>
            <div className={regPriceWrapper}>
              <span className={regPriceText}>Regular price:</span>
              <span className={regPriceNumber}>$20</span>
            </div>
            <div className={specPriceWrapper}>
              <span
                className={specPriceEmoji}
                role={"img"}
                aria-label={"Partying Face"}
              >
                🥳
              </span>
              <span className={specPriceText}>Special price offer:</span>
              <div className={separator} />
              <span className={specPriceNumber}>$15</span>
            </div>
            <div className={saveWrapper}>
              <p>
                By purchasing right now
                <br />
                <span>you save 25%!</span>
              </p>
              <Link
                arrow={true}
                href={"https://szadamiak.gumroad.com/l/frontendjob"}
                target="_blank"
                rel="noreferrer"
              >
                I'M BUYING THE BOOK
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BuyBook;
