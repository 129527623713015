import React from "react";
import {
  wrapper,
  bookCover,
  rating,
  textContainer,
  rainbow,
  shape,
  rectangle,
  bg,
  imageContainer,
  buttonContainer,
  description,
  title,
  rainbowMobile,
  mobileButtonContainer,
  coverFeature,
} from "./Header.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import imgHeart from "./../../assets/images/heart.svg";
import imgRainbow from "./../../assets/images/rainbow.svg";
import imgRectangle from "./../../assets/images/pixel-dots/dot7.svg";
import imgShape from "../../assets/images/cloud2.svg";
import Link from "../Link/Link";
import mobileRainbow from "../../assets/images/rainbow-horizontal2.svg";

const Header = () => {
  return (
    <header className={wrapper}>
      <div className={textContainer}>
        <div className={rating}>
          <img src={imgHeart} alt={"serce"} />
          Loved by our readers so far!
          <span role={"img"} aria-label={"stars"}>
            ⭐️⭐️⭐️⭐️⭐️
          </span>
          (5.00)
        </div>
        <h1 className={title}>
          Stand out among the crowd of developers and land your dream frontend
          job.
        </h1>
        <p className={description}>
          Discover tested strategies for nailing every part of the recruitment
          process, from creating the portfolio to the on-site interview.
        </p>
        <div className={buttonContainer}>
          <Link
            modifier={"outline"}
            href={"https://www.findfrontendjob.com#bookPreview"}
          >
            WHAT’S INSIDE?
          </Link>
          <Link
            arrow={true}
            href={"https://szadamiak.gumroad.com/l/frontendjob"}
            target="_blank"
            rel="noreferrer"
          >
            I WANT THE BOOK!
          </Link>
        </div>
      </div>
      <div className={imageContainer}>
        <StaticImage
          src={"../../assets/images/cover.png"}
          className={bookCover}
          alt={"okładka"}
          formats={["webp", "avif"]}
          width={369}
          height={593}
          placeholder={"blurred"}
        />
        <img
          src={imgRainbow}
          className={rainbow}
          role={"presentation"}
          alt={""}
        />
        <img
          src={imgRectangle}
          className={rectangle}
          role={"presentation"}
          alt={""}
        />
        <img
          src={imgRectangle}
          className={rectangle}
          role={"presentation"}
          alt={""}
        />
        <img
          src={imgRectangle}
          className={rectangle}
          role={"presentation"}
          alt={""}
        />
        <img
          src={imgRectangle}
          className={rectangle}
          role={"presentation"}
          alt={""}
        />
        <img
          src={imgRectangle}
          className={rectangle}
          role={"presentation"}
          alt={""}
        />
        <img
          src={imgRectangle}
          className={rectangle}
          role={"presentation"}
          alt={""}
        />
        <img
          src={imgRectangle}
          className={rectangle}
          role={"presentation"}
          alt={""}
        />
        <img
          src={imgRectangle}
          className={rectangle}
          role={"presentation"}
          alt={""}
        />
        <img
          src={mobileRainbow}
          className={rainbowMobile}
          role={"presentation"}
          alt={""}
        />
        <div className={coverFeature}>
          <span>100%</span>positive
          <br />
          reviews
        </div>
        <div className={coverFeature}>
          <span>+100</span>pages
        </div>
      </div>
      <img src={imgShape} className={shape} role={"presentation"} alt={""} />
      <img src={imgShape} className={shape} role={"presentation"} alt={""} />
      <img src={imgShape} className={shape} role={"presentation"} alt={""} />
      <img src={imgShape} className={shape} role={"presentation"} alt={""} />
      <img src={imgShape} className={shape} role={"presentation"} alt={""} />
      <img src={imgShape} className={shape} role={"presentation"} alt={""} />
      <div className={bg} />
      <div className={mobileButtonContainer}>
        <Link
          modifier={"outline"}
          href={"https://www.findfrontendjob.com#bookPreview"}
        >
          WHAT’S INSIDE?
        </Link>
        <Link
          arrow={true}
          href={"https://gumroad.com/l/aqWog"}
          target="_blank"
          rel="noreferrer"
        >
          I WANT THE BOOK!
        </Link>
      </div>
    </header>
  );
};

export default Header;
