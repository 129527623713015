import React from 'react'
import { container, classArrow, green, outline } from './Link.module.scss'
import imgArrow from './../../assets/images/arrowClean.svg'

const Link = ({ children, href, modifier, arrow, target, rel }) => {

  let myModifier
  if (modifier === 'outline') { myModifier = outline }
  if (modifier === 'green') { myModifier = green }
  return (
    <a href={href} className={`${container} ${myModifier}`} target={target} rel={rel}>
      {children}
      {arrow && <img src={imgArrow} className={classArrow} alt={''} role={'presentation'} />}
    </a>
  )
}

export default Link