import React from "react";
import {
  wrapper,
  title,
  heartIcon,
  person,
  personImage,
  personName,
  personLink,
  container,
  peopleContainer,
  personMedium,
  personTwitter,
  personLinkedIn,
  featureText,
  featureIcon,
  feature,
  featureContainer,
  oval1,
  oval2,
} from "./MadeBy.module.scss";
import heart from "../../assets/images/heart.svg";
import mediumImg from "../../assets/images/medium.svg";
import linkedInImg from "../../assets/images/linkedinIcon.svg";
import twitterImg from "../../assets/images/twitterIcon.svg";
import youtubeImg from "../../assets/images/yt.svg";
import { StaticImage } from "gatsby-plugin-image";
const MadeBy = () => {
  return (
    <section className={wrapper}>
      <div className={oval1} />
      <div className={oval2} />
      <h2 className={title}>
        Made by developers{" "}
        <img src={heart} className={heartIcon} alt={"heart emoji"} />
        <br />
        for developers (and everyone else!)
      </h2>
      <div className={container}>
        <div className={peopleContainer}>
          <div className={person}>
            <StaticImage
              src={"../../assets/images/madeBy1.png"}
              className={personImage}
              alt={"Szymon Adamiak"}
              width={58}
              height={58}
              formats={["webp", "avif"]}
            />
            <strong className={personName}>Szymon Adamiak</strong>
            <a
              href={"https://sadamiak.medium.com/"}
              target="_blank"
              rel="noreferrer"
              className={personLink}
            >
              <img
                src={mediumImg}
                className={personMedium}
                alt={"Szymon Adamiak medium profile"}
              />
            </a>
            <a
              href={"https://twitter.com/s_adamiak"}
              target="_blank"
              rel="noreferrer"
              className={personLink}
            >
              <img
                src={twitterImg}
                className={personTwitter}
                alt={"Szymon Adamiak twitter profile"}
              />
            </a>
            <a
              href={"https://www.linkedin.com/in/szymon-adamiak-620867129"}
              target="_blank"
              rel="noreferrer"
              className={personLink}
            >
              <img
                src={linkedInImg}
                className={personLinkedIn}
                alt={"Szymon Adamiak linkedin profile"}
              />
            </a>
          </div>
          <div className={person}>
            <StaticImage
              src={"../../assets/images/michal@2x.webp"}
              className={personImage}
              alt={"Michał Malewicz"}
              width={58}
              height={58}
              formats={["webp", "avif"]}
            />
            <strong className={personName}>Michał Malewicz</strong>
            <a
              href={"https://michalmalewicz.medium.com/"}
              target="_blank"
              rel="noreferrer"
              className={personLink}
            >
              <img
                src={mediumImg}
                className={personMedium}
                alt={"Michał Malewicz Medium profile"}
              />
            </a>
            <a
              href={"https://twitter.com/michalmalewicz"}
              target="_blank"
              rel="noreferrer"
              className={personLink}
            >
              <img
                src={twitterImg}
                className={personTwitter}
                alt={"Michał Malewicz Twitter profile"}
              />
            </a>
            <a
              href={"https://www.youtube.com/c/MalewiczHype"}
              target="_blank"
              rel="noreferrer"
              className={personLink}
            >
              <img
                src={youtubeImg}
                className={personLinkedIn}
                alt={"Michał Malewicz youtube"}
              />
            </a>
          </div>
        </div>
        <div className={featureContainer}>
          <div className={feature}>
            <span
              className={featureIcon}
              role={"img"}
              aria-label={"technologist"}
            >
              🧑🏻‍💻
            </span>
            <div className={featureText}>
              We’re professional Product Designers with +25 years of industry
              experience combined.
            </div>
          </div>
          <div className={feature}>
            <span className={featureIcon} role={"img"} aria-label={"briefcase"}>
              💼
            </span>
            <div className={featureText}>
              We have an agency background, and decided to run our own - HYPE4 -
              succesfully for 8 years straight and counting!
            </div>
          </div>
          <div className={feature}>
            <span className={featureIcon} role={"img"} aria-label={"trophy"}>
              🏆
            </span>
            <div className={featureText}>
              We did projects for both big, established companies and small
              startups. Currently we have around 500 finished projects under our
              belts.
            </div>
          </div>
          <div className={feature}>
            <span
              className={featureIcon}
              role={"img"}
              aria-label={"graduation cap"}
            >
              🎓
            </span>
            <div className={featureText}>
              We talk at various Design Conferences, and Michał teaches UI
              Design at a Univeristy in Poland.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MadeBy;
