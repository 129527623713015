import React from 'react'
import imgPerson1 from '../../assets/images/ppl/blank.png'
import imgPerson2 from '../../assets/images/ppl/p1_2.jpg'
import imgPerson3 from '../../assets/images/ppl/p1.jpg'
import imgPerson4 from '../../assets/images/ppl/p2_2.jpg'
import imgPerson5 from '../../assets/images/ppl/p2.png'
import imgPerson6 from '../../assets/images/ppl/p3.jpg'
import imgPerson7 from '../../assets/images/ppl/p4_2.jpg'
import imgPerson8 from '../../assets/images/ppl/p4.png'
import imgPerson9 from '../../assets/images/ppl/p5.jpg'
import imgPerson10 from '../../assets/images/ppl/p6.jpg'
import imgPerson11 from '../../assets/images/ppl/p7.jpg'
import { container, itemWrapper, itemContainer, tweetImg, wrapper, slider, slideTrack, sliderSecond } from './TweetCarousel.module.scss'
const TweetCarousel = () => {

  const tweetData = [
    {
      img: imgPerson1,
      text: 'Pixelperfect plugin? I didn\'t know about it',
      author: 'Fabiana48993306'
    },
    {
      img: imgPerson2,
      text: 'This would be full of meat for every frontend webdev :) - @danielnoworyta',
      author: 'danielnoworyta'
    },
    {
      img: imgPerson3,
      text: 'I love the tricks on using HotJar to track interaction',
      author: 'wannable00'
    },
    {
      img: imgPerson4,
      text: '🦄 #frontendunicorns are certainly real 😉 💫 🌈',
      author: 'kelseru'
    },
    {
      img: imgPerson5,
      text: 'Tons of great info broken down into easy to understand pieces',
      author: 'thedavefulton'
    },
    {
      img: imgPerson6,
      text: 'Authentic content and format, well crafted!',
      author: 'GKetsiakidis'
    },
    {
      img: imgPerson7,
      text: 'I love those resources!',
      author: 'clstclaire_'
    },
    {
      img: imgPerson8,
      text: 'Great advice for junior devs to take their careers to the next level.',
      author: 'Mae_East'
    },
    {
      img: imgPerson9,
      text: 'Looks promising for senior devs too',
      author: 'GiovanniLondero'
    },
    {
      img: imgPerson10,
      text: 'This series of e-books look on point, how exciting!',
      author: 'a_blackunicorn'
    },
    {
      img: imgPerson11,
      text: 'It\'s a great resource. The design of the book is also awesome.',
      author: 'adeleke5140'
    },
  ]

  return (
    <section className={wrapper}>
      <div className={container}>
        <div className={slider}>
          <div className={slideTrack}>
            {tweetData.concat(tweetData).map((tweet, index) =>
              <div className={itemWrapper} key={index}>
                <div className={itemContainer}>
                  <img src={tweet.img} width={40} height={40} className={tweetImg} alt={`${tweet.author} twitter profile`} />
                  {tweet.text} - @{tweet.author}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={sliderSecond}>
          <div className={slideTrack}>
            {tweetData.concat(tweetData).reverse().map((tweet, index) =>
              <div className={itemWrapper} key={index}>
                <div className={itemContainer}>
                  <img src={tweet.img} width={40} height={40} className={tweetImg} alt={`${tweet.author} twitter profile`} />
                  {tweet.text} - @{tweet.author}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}


export default TweetCarousel;